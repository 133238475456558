var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "transition",
        { attrs: { name: "fade-in-up" } },
        [
          _c("router-view", {
            attrs: { isProxy: _vm.isProxy, isCustomer: _vm.isCustomer },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }